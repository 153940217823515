









export default {
    name: "image_preview",
    props: {
        width: {
            type: Number,
            default: () => 600
        }
    },
    data() {
        return {
            url: '',
            visible: false
        }
    },
    methods: {
        show(url: string) {
            let self: any = this;
            self.url = url;
            self.visible = true;
        }
    }
}
